<template>
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    append-to-body
    @close="closedFn"
    width="450px"
  >
    <div class="p-lr-20">
      <el-form
        :model="ruleForm"
        :rules="rules"
        :show-message="false"
        ref="address"
        size="small"
        label-width="86px"
        class="ruleForm m-tb-20"
      >
        <el-form-item label="联系人" prop="receiver">
          <el-input v-model.trim="ruleForm.receiver" type="text" maxlength="20" clearable placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model.trim="ruleForm.phone" maxlength="11" type="text" clearable placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="地区" prop="areaId">
          <SelectArea
            style="width: 100%"
            size="small"
            :areaData="ruleForm.areaIds"
            :area-id="ruleForm.areaId"
            @change="areaChange"
          />
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input type="textarea" placeholder="请输入详细地址" v-model="ruleForm.address" maxlength="200"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="ruleForm.isDefault">设为默认地址</el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="text-right">
      <el-button plain size="small" @click="closedFn">取消</el-button>
      <el-button type="primary" size="small" @click="onSave">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addAddresses, updateAddresses } from "@/api/addresses";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import { mobileValidate } from "@/utils/validate";

const defaultForm = {
  receiver: "", // 联系人
  phone: "", // 手机号
  areaId: 0, // 地区
  areaName: "", // 地区
  areaIds: [], // 地区
  address: "", // 详细地址
  isDefault: false // 设为默认地址
};
export default {
  name: "EditAddressBox",
  components: {
    SelectArea
  },
  props: {
    isEdit: Boolean,
    curRow: Object
  },
  data() {
    return {
      ruleForm: {
        ...defaultForm
      },
      rules: {
        receiver: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: mobileValidate, trigger: "blur" }
        ],
        areaId: [{ required: true, message: "请选择地区", trigger: "change" }],
        address: [{ required: true, message: "请输入详细地址", trigger: "change" }]
      }
    };
  },
  watch: {
    "$attrs.visible": {
      handler(val) {
        if (val) {
          if (this.$refs.address) {
            this.$refs.address.resetFields();
          }
          this.ruleForm = { ...this.curRow };
        } else {
          this.ruleForm = { ...defaultForm };
        }
      }
    }
  },
  computed: {
    title() {
      return this.isEdit ? "编辑收货地址" : "新增收货地址";
    }
  },
  methods: {
    areaChange(dataObj) {
      this.ruleForm.areaIds = dataObj.areaIds;
      this.ruleForm.areaId = dataObj.areaId;
      this.ruleForm.areaName = dataObj.areaName;
    },
    closedFn() {
      this.$emit("update:visible", false);
    },
    onSave() {
      this.$refs.address.validate((valid, err) => {
        if (valid) {
          if (!this.ruleForm.areaId) {
            this.$message.warning({ message: "请选择地区", showClose: true });
            return;
          }
          if (this.isEdit) {
            // 调用修改接口
            // 调用新增接口
            const params = {
              id: this.curRow.id,
              receiver: this.ruleForm.receiver,
              phone: this.ruleForm.phone,
              areaId: this.ruleForm.areaId,
              areaName: this.ruleForm.areaName,
              address: this.ruleForm.address,
              isDefault: this.ruleForm.isDefault
            };
            updateAddresses(params)
              .then(() => {
                this.$message.success("修改成功");
                this.$emit("refresh");
                this.closedFn();
              })
              .catch(() => {
                this.$message.error("修改失败");
              });
          } else {
            // 调用新增接口
            const params = {
              receiver: this.ruleForm.receiver,
              phone: this.ruleForm.phone,
              areaId: this.ruleForm.areaId,
              areaName: this.ruleForm.areaName,
              address: this.ruleForm.address,
              isDefault: this.ruleForm.isDefault
            };
            addAddresses(params)
              .then(() => {
                this.$message.success("新增成功");
                this.$emit("refresh");
                this.closedFn();
              })
              .catch(err => {
                this.$message.error(err.message || "新增失败");
              });
          }
        } else {
          const errTips = err[Object.keys(err)[0]][0].message;
          this.$message.warning({ message: errTips, showClose: true });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-checkbox {
  font-weight: 400;
}
</style>
