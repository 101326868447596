<template>
  <div class="address">
    <transition name="el-zoom-in-top">
      <div class="flex flex-between" v-show="showMoreAddress">
        <div>
          <div class="flex flex-row-center" v-for="item in addressList" :key="item.id">
            <el-radio v-model="addressRadio" :label="item.id" @change="selectAddress(item)">
              <span class="m-l-10">{{ item.receiver }}</span>
              <span class="m-l-10"> {{ item.provinceName }}/{{ item.cityName }}/{{ item.areaName }} </span>
              <span class="m-l-10">{{ item.address }}</span>
              <span class="m-l-10">{{ item.phone }}</span>
            </el-radio>
            <span v-if="item.isDefault" class="flag m-r-10">默认地址</span>
            <el-button v-if="!item.isDefault" type="text" size="small" @click="setDefaultFn(item)">
              设为默认地址
            </el-button>
            <el-button type="text" size="small" @click="editAddressFn(item)">编辑</el-button>
            <el-button v-if="!item.isDefault" type="text" size="small" @click="deleteAddressFn(item)">
              删除
            </el-button>
          </div>
          <div class="text-999" v-if="!addressList.length">暂无地址信息</div>
        </div>
        <div>
          <el-button type="text" size="small" @click="addAddressFn">新增收货地址</el-button>
        </div>
      </div>
    </transition>
    <div>
      <el-button type="text" size="small" @click="showMoreAddress = !showMoreAddress">
        {{ showMoreAddress ? "收起地址" : "更多地址" }}
        <i :class="[showMoreAddress ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right', 'transform-90']"></i>
      </el-button>
    </div>
    <!-- 新增/编辑收货地址 -->
    <EditAddressBox :visible.sync="editAddressVisible" :curRow="curRow" :isEdit="isEdit" @refresh="getAddresses()" />
  </div>
</template>

<script>
import { getAddresses, deleteAddresses, setDefaultAddresses } from "@/api/addresses";
import EditAddressBox from "@/views/manage/address/components/EditAddressBox.vue";

export default {
  name: "EditAddress",
  props: {
    addressInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    EditAddressBox
  },
  data() {
    return {
      addressRadio: 0,
      showMoreAddress: true, //是否展示更多地址
      addressList: [],
      addressObj: {},
      editAddressVisible: false,
      curRow: {},
      isEdit: false
    };
  },
  watch: {
    addressInfo: {
      handler() {
        this.getAddresses();
      },
      deep: true
    }
  },
  methods: {
    // 获取地址列表
    getAddresses() {
      getAddresses().then(res => {
        this.addressList = res || [];
        this.addressList.map(item => {
          if (this.addressInfo.type == "modify") {
            if (
              this.addressInfo.receiver === item.receiver &&
              this.addressInfo.receiverAddress === item.address &&
              this.addressInfo.receiverAreaId === item.areaId &&
              this.addressInfo.receiverPhone === item.phone
            ) {
              this.addressRadio = item.id;
              this.$emit("select", item);
            }
          } else {
            if (item.isDefault) {
              this.addressRadio = item.id;
              this.$emit("select", item);
            }
          }
        });
      });
    },
    // 新建地址
    addAddressFn() {
      this.curRow = {};
      this.isEdit = false;
      this.editAddressVisible = true;
    },
    // 删除地址
    deleteAddressFn(row) {
      this.$confirm(`是否确认删除此收货地址？`, "提示", { type: "warning" })
        .then(() => {
          const data = {
            id: row.id
          };
          deleteAddresses(data)
            .then(() => {
              this.getAddresses();
              this.$message.success("删除成功");
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    // 编辑地址
    editAddressFn(row) {
      this.curRow = { ...row };
      this.isEdit = true;
      this.editAddressVisible = true;
    },
    // 设为默认地址
    setDefaultFn(row) {
      const data = {
        id: row.id
      };
      setDefaultAddresses(data)
        .then(() => {
          this.$message.success("设置成功");
          this.getAddresses();
        })
        .catch(() => {
          this.$message.error("设置失败");
        });
    },
    // 选择地址
    selectAddress(item) {
      this.$emit("select", item);
    }
  }
};
</script>

<style lang="less" scoped>
.address {
  .flag {
    background: #f56c6c;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 12px;
  }
  /deep/.el-button--small {
    padding: 8px 0;
  }
  .transform-90 {
    transform: rotate(90deg);
  }
  .noData {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
  }
}
</style>
