import request from "@/utils/request";

/**
 * 地址列表
 */
export function getAddresses(data) {
  return request({
    url: "/mall/addresses",
    method: "get",
    params: data
  });
}

/**
 * 地址详情
 */
export function getAddressesDetails(data) {
  return request({
    url: "/mall/addresses/" + data.id,
    method: "get",
    params: data
  });
}

/**
 * 新增地址
 */
export function addAddresses(data) {
  return request({
    url: "/mall/addresses",
    method: "post",
    data: data
  });
}

/**
 * 删除地址
 */
export function deleteAddresses(data) {
  return request({
    url: "/mall/addresses/" + data.id,
    method: "delete",
    data: data
  });
}

/**
 * 更新地址
 */
export function updateAddresses(data) {
  return request({
    url: "/mall/addresses/" + data.id,
    method: "post",
    data: data
  });
}

/**
 * 设置默认地址
 */
export function setDefaultAddresses(data) {
  return request({
    url: `/mall/addresses/${data.id}/default`,
    method: "post",
    data: data
  });
}
